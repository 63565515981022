<template>
  <power-bi code="eyJrIjoiYzliNDYyYjQtMTIwMS00ZmYwLTllNjctMjlmYTk5NDMwM2Y2IiwidCI6ImM4NDJjMWQ5LWQzNjQtNDcxNi1iN2UzLWNhNDgxYTIzZDZhYyJ9" />
</template>

<script>
import PowerBi from '../reports/components/PowerBi.vue';

export default {
    components: { PowerBi },
};
</script>
